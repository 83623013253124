/** @jsx jsx */
import { Flex, jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import some from "lodash/some"
import get from "lodash/get"
import { findBySlug } from "../helpers"
import queryString from "query-string"
import InfiniteScroll from "react-infinite-scroller"
import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import Hero from "../components/hero"
import Page from "../components/page"
import Row from "../components/row"
import Text from "../components/text"
import { getValueRange, getValueList } from "../helpers"
import { paramsCheck } from "../functions/functions"
import CTA from "../components/cta"
import theme from "../gatsby-plugin-theme-ui/index"

import { mediaQueryList } from "../helpers"

import {
  Button,
} from "@theme-ui/components"
import "../components/layout.css"

const LivingHomesPage = ({ data, location }) => {
  paramsCheck();
  const [isReady, setIsReady] = React.useState(false)
  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.location.href = "/plant-design-studio/";
      setIsReady(true)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }
  React.useEffect(() => {
    if (checkIfWindow) {
      window.location.href = "/plant-design-studio/";
      setIsReady(true)
    }
  }, [])
  
  const bedsQuery =
    queryString.parse(location.search).beds ||
    queryString.parse(location.hash.substr(2)).beds
  // console.log("query string",queryString.parse(location.hash.substr(2)),"a",location.search,"b<",location.hash.substr(2))
  const multifamilyQuery =
    queryString.parse(location.search).multifamily ||
    queryString.parse(location.hash.substr(2)).multifamily
  const heroBladePatternData = findBySlug({
    list: data.contentfulPage.bladePatternList,
    slug: "models-hero-module",
  })

  // let filterTitle;
  const [filterTitle, setFilterTitle] = React.useState()

  const renderModels = () => {
    const [minBeds, maxBeds] = bedsQuery
      ? bedsQuery.split("-").map(value => parseInt(value))
      : [undefined, undefined]
    if (bedsQuery === "0-1") {
      // filterTitle = "ADUs (Detached Units)"
      setFilterTitle("ADUs (Detached Units)")
    } else if (bedsQuery && maxBeds === 0) {
      // filterTitle = `${minBeds}+ Bedroom Models`
      setFilterTitle(`${minBeds}+ Bedroom Models`)
    } else if (bedsQuery) {
      // filterTitle = `${bedsQuery} Bedroom Models`
      setFilterTitle(`${bedsQuery} Bedroom Models`)
    } else if (multifamilyQuery) {
      // filterTitle = `Multi-Family Units`
      setFilterTitle(`Multi-Family Units`)
    } else if (!bedsQuery && !multifamilyQuery) {
      setFilterTitle("")
    }

    let modelParamsFilter = data.contentfulSequence.orderItems
      .filter(
        model =>
          model.model__variation_ &&
          model.model__variation_.length &&
          some(
            model.model__variation_,
            home => home.images && home.images.length
          )
      )
      .filter(model => {
        const filterBeds = () => {
          if (bedsQuery) {
            const bedList = getValueList(model.model__variation_, "beds")
            if (maxBeds !== 0) {
              return bedList.some(beds => beds >= minBeds && beds <= maxBeds)
            } else return bedList.some(beds => beds >= minBeds)
          } else return true
        }
        const filterMultifamily = () => {
          if (multifamilyQuery) {
            return model.multifamilyUnit
          } else return true
        }
        return filterBeds() && filterMultifamily()
      })

    setModels(modelParamsFilter)
  }

  // console.log("data==>",data.contentfulSequence.orderItems)

  const [resetHover, setResetHover] = React.useState(false)
  const [sizeFilter, setSizeFilter] = React.useState({
    lessThan500sqFt: false,
    Between501to1200sqFt: false,
    Between1201to1800sqFt: false,
    Between1801to2200sqFT: false,
    Between2201to2800sqFT: false,
    Between2801to3300sqFt: false,
    GreaterThan3300sqFt: false,
    Studio: false,
    Bedrooms_1_2: false,
    Bedrooms_3_4: false,
    Bedrooms_5: false,
  })
  const [designerFilter, setDesignerFilter] = React.useState({
    alchemyArchitectures: false,
    brookScarpa: false,
    dougBurdge: false,
    kieranTimberlake: false,
    kotoDesign: false,
    plantDesignStudio: false,
    rayKappe: false,
    richardPedranti: false,
    yvesBehar: false,
  })
  const [filterIsActive, setFilterIsActive] = React.useState(false)

  const filterEvent = e => {
    setSizeFilter({ ...sizeFilter, [e.target.id]: !sizeFilter[e.target.id] })
  }
  const designerFilterEvent = e => {
    setDesignerFilter({
      ...designerFilter,
      [e.target.id]: !designerFilter[e.target.id],
    })
  }

  const clearFilter = clearFilter => {
    if (clearFilter === "sizeFilter") {
      let obj = {}
      for (let key in sizeFilter) {
        obj[key] = false
      }
      setSizeFilter({ ...obj })
    } else if (clearFilter === "designerFilter") {
      let obj = {}
      for (let key in designerFilter) {
        obj[key] = false
      }
      setDesignerFilter({ ...obj })
    } else if (clearFilter === "all") {
      let obj = {}
      for (let key in sizeFilter) {
        obj[key] = false
      }
      let designerObj = {}
      for (let key in designerFilter) {
        obj[key] = false
      }
      setSizeFilter({ ...obj })
      setDesignerFilter({ ...designerObj })
    }
  }

  const {
    lessThan500sqFt,
    Between501to1200sqFt,
    Between1201to1800sqFt,
    Between1801to2200sqFT,
    Between2201to2800sqFT,
    Between2801to3300sqFt,
    GreaterThan3300sqFt,
    Studio,
    Bedrooms_1_2,
    Bedrooms_3_4,
    Bedrooms_5,
  } = sizeFilter

  const {
    alchemyArchitectures,
    brookScarpa,
    dougBurdge,
    kieranTimberlake,
    kotoDesign,
    plantDesignStudio,
    rayKappe,
    richardPedranti,
    yvesBehar,
  } = designerFilter

  const fullWidthList = [true, true, true]
  const [models, setModels] = React.useState()
  // let models = data.contentfulSequence.orderItems

  // .filter(model=>{
  //   const checkAllFilter = Object.values(sizeFilter).filter(ele=>ele);
  //   if(checkAllFilter.length>0){
  //    console.log("model==>",model);
  //    const squareFootage = getValueList(model.model__variation_,"squareFootage")
  //    console.log("SqFt==>",squareFootage)
  //    let obj ;
  //     if(lessThan500sqFt){
  //         obj = squareFootage.some(squareFootage=>squareFootage<=500)
  //         console.log("obj===>",obj)
  //       //  return obj
  //     }
  //     if(Between501to1200sqFt){
  //         obj = squareFootage.some(squareFootage=>squareFootage>=501 && squareFootage<=1200)
  //     }
  //     if(Between1201to1800sqFt){
  //         obj = squareFootage.some(squareFootage=>squareFootage>=1201 && squareFootage<=1800)
  //     }
  //     if(Between1801to2200sqFT){
  //         obj = squareFootage.some(squareFootage=>squareFootage>=1801 && squareFootage<=2200)
  //     }
  //     return obj
  //     // return getValueList(model.model__variation_,"")
  //   }else {

  //   }
  // })
  // setModels(modelParamsFilter)

  const [cardsLimit, setCardsLimit] = React.useState(10)

  React.useEffect(() => {
    clearFilter("all")
    renderModels();
    document.head.innerHTML += '<meta name="robots" content="noindex">';
  }, [])

  React.useEffect(() => {
    // const checkAllFilter = Object.values({...sizeFilter,...designerFilter}).filter(ele => ele)

    // if (checkAllFilter.length > 0) {
    //   console.log("eventfire==>")
    // let lessthan500 = [];
    // let Between501to1200 = [];
    // let Between1201to1800 = [];
    // let Between1801to2200 = [];
    // let Between2201to2800 = [];
    // let Between2801to3300 = [];
    // let GreaterThan3300 = [];
    // let Stud = [];
    // let Bedrooms_1_2_ = [];
    // let Bedrooms_3_4_ = [];
    // let Bedrooms_5_   = [];
    let sizeFilter = []
    if (lessThan500sqFt) {
      // lessthan500
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(squareFootage => squareFootage <= 500)
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Between501to1200sqFt) {
      // Between501to1200
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return (
            squareFootage.some(
              squareFootage => squareFootage >= 501 && squareFootage <= 1200
            ) ||
            // living home lighthosue model add
            model.title.includes("LightHouse") ||
            model.title.includes("lightHouse ")
          )
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Between1201to1800sqFt) {
      // Between1201to1800
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(
            squareFootage => squareFootage >= 1201 && squareFootage <= 1800
          )
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Between1801to2200sqFT) {
      // Between1801to2200
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(
            squareFootage => squareFootage >= 1801 && squareFootage <= 2200
          )
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Between2201to2800sqFT) {
      // Between2201to2800
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(
            squareFootage => squareFootage >= 2201 && squareFootage <= 2800
          )
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Between2801to3300sqFt) {
      // Between2801to3300
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(
            squareFootage => squareFootage >= 2801 && squareFootage <= 3300
          )
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (GreaterThan3300sqFt) {
      // GreaterThan3300
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const squareFootage = getValueList(
            model.model__variation_,
            "squareFootage"
          )
          return squareFootage.some(squareFootage => squareFootage >= 3300)
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Studio) {
      // Stud
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const filterBeds = () => {
            // if (bedsQuery) {
            const bedList = getValueList(model.model__variation_, "beds")
            // if (maxBeds !== 0) {
            return (
              bedList.some(beds => beds === 0) ||
              model.slug.includes("lighthouse-livinghome")
            )
            //   } else return bedList.some(beds => beds >= minBeds)
            // } else return true
            // }
          }

          return filterBeds()
        })

      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Bedrooms_1_2) {
      // Bedrooms_1_2_
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const filterBeds = () => {
            // if (bedsQuery) {
            const bedList = getValueList(model.model__variation_, "beds")
            // if (maxBeds !== 0) {
            return bedList.some(beds => beds >= 1 && beds <= 2)
            //   } else return bedList.some(beds => beds >= minBeds)
            // } else return true
            // }
          }

          return filterBeds()
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Bedrooms_3_4) {
      // Bedrooms_3_4_
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const filterBeds = () => {
            // if (bedsQuery) {
            const bedList = getValueList(model.model__variation_, "beds")
            // if (maxBeds !== 0) {
            return bedList.some(beds => beds >= 3 && beds <= 4)
            //   } else return bedList.some(beds => beds >= minBeds)
            // } else return true
            // }
          }

          return filterBeds()
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    if (Bedrooms_5) {
      // Bedrooms_5_
      const filterArr = data.contentfulSequence.orderItems
        .filter(
          model =>
            model.model__variation_ &&
            model.model__variation_.length &&
            some(
              model.model__variation_,
              home => home.images && home.images.length
            )
        )
        .filter(model => {
          const filterBeds = () => {
            // if (bedsQuery) {
            const bedList = getValueList(model.model__variation_, "beds")
            // if (maxBeds !== 0) {
            return bedList.some(beds => beds >= 5)
            //   } else return bedList.some(beds => beds >= minBeds)
            // } else return true
            // }
          }

          return filterBeds()
        })
      sizeFilter = [...sizeFilter, ...filterArr]
    }
    let designerFilter = []
    let desingnArr =
      sizeFilter.length > 0
        ? [...sizeFilter]
        : [...data.contentfulSequence.orderItems]
    if (alchemyArchitectures) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Alchemy Architects")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (brookScarpa) {
      const filterArr = desingnArr
        // .filter(model=> model.title.includes("Brooks + Scarpa"))
        .filter(
          designer =>
            designer.designPartner &&
            designer.designPartner.name.includes("Brooks + Scarpa")
        )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (dougBurdge) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Douglas W. Burdge")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (kieranTimberlake) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("KieranTimberlake")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (kotoDesign) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner && designer.designPartner.name.includes("Koto")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (plantDesignStudio) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Plant Design Studio")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (rayKappe) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Ray Kappe")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (richardPedranti) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Richard Pedranti")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }
    if (yvesBehar) {
      const filterArr = desingnArr.filter(
        designer =>
          designer.designPartner &&
          designer.designPartner.name.includes("Yves Béhar")
      )
      designerFilter = [...designerFilter, ...filterArr]
    }

    // let models = [...lessthan500,...Between501to1200,...Between1201to1800,
    //               ...Between1801to2200,...Between2201to2800,...Between2801to3300,
    //               ...GreaterThan3300,...Stud,...Bedrooms_1_2_,...Bedrooms_3_4_,
    //               ...Bedrooms_5_,...designerFilter];
    // let models = [...sizeFilter,...designerFilter];
    let models = [...sizeFilter]
    if (
      alchemyArchitectures ||
      brookScarpa ||
      dougBurdge ||
      kieranTimberlake ||
      kotoDesign ||
      plantDesignStudio ||
      rayKappe ||
      richardPedranti ||
      yvesBehar
    ) {
      models = [...designerFilter]
    }
    if (!bedsQuery && !multifamilyQuery) {
      setModels(
        models.length > 0
          ? [...new Set(models)]
          : checkAllFilter()
          ? models
          : data.contentfulSequence.orderItems
      )
      setFilterTitle("")
    }

    // } else {
    //   // setModels(modelParamsFilter)
    //   renderModels()
    // }
  }, [sizeFilter, designerFilter])

  React.useEffect(() => {
    if (bedsQuery || multifamilyQuery) {
      clearFilter("all")
      renderModels()
    } else {
      renderModels()
    }
  }, [bedsQuery, multifamilyQuery])

  const renderViewAllButton = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "model-view-all-livinghomes-button",
    })
    if (!bladePatternData) return

    // clearFilter("all");

    return (
      <Flex
        sx={{
          justifyContent: ["flex-start", "flex-start", "flex-end"],
        }}
      >
        <CTA
          link={"/models"}
          type="secondary"
          linkType="internal"
          onClick={() => clearFilter("all")}
        >
          {bladePatternData.ctaTitle}
        </CTA>
      </Flex>
    )
  }

  const renderFilter = () => {
    const headerHeight = [145, 125, 72, 60]

    return (
      <div
        sx={{
          position: "relative",
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            backgroundColor: "white",
            height: headerHeight,
            width: "100%",
            transition: theme => `background-color ${theme.transitions}`,
            ...{
              bg: "accent",
              // position: "fixed",
              // top: 0,
              zIndex: 2,
            },
          }}
        >
          <Container>
            <Row
              customStyle={{
                alignItems: "center",
              }}
            >
              <Column
                size={[12, 12, 3, 2, 2, 1]}
                sx={
                  {
                    // alignItems:"center"
                  }
                }
              >
                <Text
                  customStyle={{
                    alignItems: "center",
                    color: "white",
                    display: "flex",
                    opacity: 0,
                    alignItems: "center",
                    pr: [0, 0, theme => theme.spacing.horizontal],
                    pb: "5px",
                    transition: theme => `opacity ${theme.transitions}`,
                    ...{ opacity: 1 },
                  }}
                  // type="h3"
                >
                  Filter by:
                </Text>
              </Column>
              <Column size={[12, 12, 4, 3, 2, 2]}>
                <div
                  className="filter-dropdown"
                  sx={{ ml: ["-5px", "-5px", 0, 0, 0, 0] }}
                >
                  <Button
                    sx={{
                      ...theme.forms.buttonChevron,
                      fontSize: 14,
                      pr: 4,
                      py: "0px",
                      pl: 19,
                      // mt: 26,
                      mx: [0, 0, 3],
                      // ml: ["-15px","-15px",0,0,3],
                      display: "flex",
                      justifyContent: "space-between",
                      width: 190,
                      color: "white",
                      border: theme => `2px solid ${theme.colors.secondary}`,
                      background: theme.colors.secondary,
                      whiteSpace: "nowrap",
                      ":hover": {
                        border: theme => `2px solid ${theme.colors.primary}`,
                        color: "white",
                        background: theme.colors.primary,
                      },
                    }}
                    onMouseOver={() => {
                      setResetHover && setResetHover("")
                    }}
                    // onFocus={() => setResetHover && setResetHover(false)}
                    onClick={() => setResetHover("Size")}
                    id="sizeButton"
                  >
                    Size
                  </Button>
                  {resetHover === "Size" && (
                    <ul
                      className="dropdown-list"
                      onMouseLeave={() => setResetHover("")}
                    >
                      <li>
                        <Text type="h4">Square Footage:</Text>
                      </li>
                      {/* <li><Input type="checkbox"/>Demo</li> */}
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          id="lessThan500sqFt"
                          checked={lessThan500sqFt}
                          value="< 500 sq ft"
                        />
                        <label for="lessThan500sqFt">&#60; 500 ft&#178;</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          id="Between501to1200sqFt"
                          checked={Between501to1200sqFt}
                          value="501 - 1,200 sq ft"
                        />
                        <label for="Between501to1200sqFt">
                          501 - 1,200 ft&#178;
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="1,201 - 1,800 sq ft"
                          id="Between1201to1800sqFt"
                          checked={Between1201to1800sqFt}
                        />
                        <label for="Between1201to1800sqFt">
                          1,201 - 1,800 ft&#178;
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="1,801 - 2,200 sq ft"
                          id="Between1801to2200sqFT"
                          checked={Between1801to2200sqFT}
                        />
                        <label for="Between1801to2200sqFT">
                          1,801 - 2,200 ft&#178;
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="2,201 - 2,800 sq ft"
                          id="Between2201to2800sqFT"
                          checked={Between2201to2800sqFT}
                        />
                        <label for="Between2201to2800sqFT">
                          2,201 - 2,800 ft&#178;
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="2,801 - 3,300 sq ft"
                          id="Between2801to3300sqFt"
                          checked={Between2801to3300sqFt}
                        />
                        <label for="Between2801to3300sqFt">
                          2,801 - 3,300 ft&#178;
                        </label>
                      </li>
                      {/* <li>
                      <input
                        type="checkbox"
                        onClick={e => filterEvent(e)}
                        value="> 3,300+ sq ft"
                        id="GreaterThan3300sqFt"
                        checked={GreaterThan3300sqFt}
                      />
                      <label for="GreaterThan3300sqFt">
                        &#62; 3,300+ ft&#178;
                      </label>
                    </li> */}
                      <li>
                        <Text type="h4">Bedrooms</Text>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="Studio"
                          id="Studio"
                          checked={Studio}
                        />
                        <label for="Studio">Studio</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="1-2 Bedrooms"
                          id="Bedrooms_1_2"
                          checked={Bedrooms_1_2}
                        />
                        <label for="Bedrooms_1_2">1-2 Bedrooms</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="3-4 Bedrooms"
                          id="Bedrooms_3_4"
                          checked={Bedrooms_3_4}
                        />
                        <label for="Bedrooms_3_4">3-4 Bedrooms</label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          onClick={e => filterEvent(e)}
                          value="5+ Bedrooms"
                          id="Bedrooms_5"
                          checked={Bedrooms_5}
                        />
                        <label for="Bedrooms_5">5+ Bedrooms</label>
                      </li>
                      <li>
                        <Button
                          sx={{
                            color: "white",
                            fontSize: 3,
                            fontFamily: theme => theme.fonts.body,
                            pl: 25,
                            pr: 20,
                            py: 2,
                            borderRadius: 9999,
                            cursor: "pointer",
                            "&::after": {
                              position: "relative",
                              bottom: "1px",
                            },
                            fontSize: 14,
                            pr: 4,
                            py: 2,
                            pl: 19,
                            mr: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            width: 110,
                            border: theme =>
                              `2px solid ${theme.colors.secondary}`,
                            background: theme.colors.secondary,
                            whiteSpace: "nowrap",
                            fontSize: "14px ",
                            fontWeight: "normal",
                            ":hover": {
                              border: theme =>
                                `2px solid ${theme.colors.primary}`,
                              background: theme.colors.primary,
                            },
                          }}
                          onClick={() => {
                            clearFilter("sizeFilter")
                          }}
                        >
                          Clear Filter
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </Column>
              <div
                className="filter-dropdown"
                sx={{
                  ml: ["5px", "5px", 1, 1, 3, 3],
                }}
              >
                <Button
                  sx={{
                    ...theme.forms.buttonChevron,
                    fontSize: 14,
                    pr: 3,
                    py: "0px",
                    pl: 19,
                    display: "flex",
                    // ml: ["-15px","-15px",3,3,3],
                    justifyContent: "space-between",
                    width: 190,
                    color: "white",
                    border: theme => `2px solid ${theme.colors.secondary}`,
                    background: theme.colors.secondary,
                    whiteSpace: "nowrap",
                    ":hover": {
                      border: theme => `2px solid ${theme.colors.primary}`,
                      color: "white",
                      background: theme.colors.primary,
                    },
                  }}
                  onMouseOver={() => {
                    setResetHover && setResetHover("")
                  }}
                  onFocus={() => setResetHover && setResetHover("designer")}
                  onClick={() => setResetHover && setResetHover("designer")}
                  id="sizeButton"
                >
                  Designer
                </Button>
                {resetHover === "designer" && (
                  <ul
                    className="dropdown-list"
                    onMouseLeave={() => setResetHover("")}
                  >
                    <li>
                      <input
                        type="checkbox"
                        value="Alchemy Architects"
                        id="alchemyArchitectures"
                        checked={alchemyArchitectures}
                        onClick={e => designerFilterEvent(e)}
                      />
                      <label for="alchemyArchitectures">
                        Alchemy Architects
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Brooks + Scarpa"
                        onClick={e => designerFilterEvent(e)}
                        id="brookScarpa"
                        checked={brookScarpa}
                      />
                      <label for="brookScarpa">Brooks + Scarpa</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Doug Burdge"
                        onClick={e => designerFilterEvent(e)}
                        id="dougBurdge"
                        checked={dougBurdge}
                      />
                      <label for="dougBurdge">Doug Burdge</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="KieranTimberlake"
                        onClick={e => designerFilterEvent(e)}
                        id="kieranTimberlake"
                        checked={kieranTimberlake}
                      />
                      <label for="kieranTimberlake">KieranTimberlake</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Koto Design"
                        onClick={e => designerFilterEvent(e)}
                        id="kotoDesign"
                        checked={kotoDesign}
                      />
                      <label for="kotoDesign">Koto Design</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Plant Design Studio"
                        onClick={e => designerFilterEvent(e)}
                        id="plantDesignStudio"
                        checked={plantDesignStudio}
                      />
                      <label for="plantDesignStudio">Plant Design Studio</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Ray Kappe"
                        id="rayKappe"
                        onClick={e => designerFilterEvent(e)}
                        checked={rayKappe}
                      />
                      <label for="rayKappe">Ray Kappe</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Richard Pedranti Architect (RPA)"
                        id="richardPedranti"
                        onClick={e => designerFilterEvent(e)}
                        checked={richardPedranti}
                      />
                      <label for="richardPedranti">
                        Richard Pedranti Architect (RPA)
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Yves Béhar"
                        onClick={e => designerFilterEvent(e)}
                        id="yvesBehar"
                        checked={yvesBehar}
                      />
                      <label for="yvesBehar">Yves Béhar</label>
                    </li>
                    <li>
                      <Button
                        sx={{
                          color: "white",
                          fontSize: 3,
                          fontFamily: theme => theme.fonts.body,
                          pl: 25,
                          pr: 20,
                          py: 2,
                          borderRadius: 9999,
                          cursor: "pointer",
                          "&::after": {
                            position: "relative",
                            bottom: "1px",
                          },
                          fontSize: 14,
                          pr: 4,
                          py: 2,
                          pl: 19,
                          mr: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          width: 110,
                          border: theme =>
                            `2px solid ${theme.colors.secondary}`,
                          background: theme.colors.secondary,
                          whiteSpace: "nowrap",
                          fontSize: "14px ",
                          fontWeight: "normal",
                          ":hover": {
                            border: theme =>
                              `2px solid ${theme.colors.primary}`,
                            background: theme.colors.primary,
                          },
                        }}
                        onClick={() => {
                          clearFilter("designerFilter")
                        }}
                      >
                        Clear Filter
                      </Button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </Container>
        </Flex>
      </div>
    )
  }

  const checkAllFilter = () => {
    const filtersObj = { ...sizeFilter, ...designerFilter }
    const activeFilter = Object.values(filtersObj).filter(ele => ele)
    return activeFilter.length > 0 ? true : false
  }

  // const bladePatternData = findBySlug({
  //   list: data.contentfulPage.bladePatternList,
  //   slug: "model-view-all-livinghomes-button",
  // })

  return (
    isReady&& <Page data={data.contentfulPage}>
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            <Hero
              background={{
                type: "image",
                source: {
                  mobileImage: heroBladePatternData.mobileImage,
                  desktopImage: heroBladePatternData.desktopImage,
                },
              }}
              {...(heroBladePatternData.description && {
                body: heroBladePatternData.description.description,
              })}
              title={heroBladePatternData.title}
              type="secondary"
              textWidth={576}
              verticalAlign="center"
              ctaList={
                get(heroBladePatternData, "ctaTitle", null)
                  ? [
                      {
                        type: "secondary",
                        text: get(heroBladePatternData, "ctaTitle", ""),
                        link: get(heroBladePatternData, "ctaDestination", "/"),
                      },
                    ]
                  : null
              }
            />
          </Column>
        </Row>
        {filterTitle ? null : renderFilter()}
      </Container>
      <Container>
        {filterTitle ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">{filterTitle}</Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
                {/* <Flex
                  sx={{
                    justifyContent: ["flex-start", "flex-start", "flex-end"],
                  }}
                >
                    <CTA 
                      link={"/models"} 
                      type="secondary" 
                      linkType="internal"
                      // onClick={()=>clearFilter("all")}
                    >
                    {bladePatternData.ctaTitle}
                    </CTA>
                </Flex> */}
              </Column>
            </Row>
          </div>
        ) : checkAllFilter() && models && models.length > 0 ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">Filtered Results</Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
              </Column>
            </Row>
          </div>
        ) : models && !models.length > 0 ? (
          <div>
            <div className="modelsrow"></div>
            <Row customStyle={{ mb: 3 }}>
              <Column size={[12, 12, 8]} customStyle={{ mt: 4 }}>
                <Text type="h2">
                  No LivingHomes design found matching your filter.
                </Text>
              </Column>
              <Column size={[12, 12, 4]} customStyle={{ mt: 4 }}>
                {renderViewAllButton()}
              </Column>
            </Row>
          </div>
        ) : null}
        {models && models.length > 0 ? (
          <InfiniteScroll
            loadMore={() => setCardsLimit(cardsLimit + 10)}
            hasMore={cardsLimit < models.length}
            sx={{ overflow: "visible!important" }}
          >
            <Row customStyle={{ my: 3, pr: 3 }}>
              {models.map((model, index) => {
                const modelImageList = []
                const columnWidth = index % 5 === 4 ? 8 : 4
                model.model__variation_.forEach(homeData => {
                  if (homeData.images && homeData.images.length) {
                    homeData.images.forEach(imageData => {
                      modelImageList.push(imageData)
                    })
                  }
                })

                return modelImageList.length && index < cardsLimit ? (
                  <Column
                    size={[12, 12, columnWidth]}
                    key={model.id}
                    customStyle={{ mt: 3, pr: 0 }}
                  >
                    <ContentCard
                      beds={getValueRange(model.model__variation_, "beds")}
                      baths={getValueRange(model.model__variation_, "baths")}
                      imageList={modelImageList}
                      squareFootage={getValueRange(
                        model.model__variation_,
                        "squareFootage"
                      )}
                      // autoScroll={true}
                      // filter={"exterior"}
                      title={model.title}
                      configuratorUrl={model.configuratorUrl}
                      titleLink={{
                        to: `/models/${model.slug}`,
                        type: "internal",
                      }}
                    />
                  </Column>
                ) : null
              })}
            </Row>
          </InfiniteScroll>
        ) : null}
      </Container>
    </Page>
  )
}

export default LivingHomesPage

export const query = graphql`
  query livingHomesPageQuery {
    contentfulPage(slug: { eq: "models" }) {
      ...PageData
      ...BladePatternData
    }

    contentfulSequence(slug: { eq: "sequence-models" }) {
      orderItems {
        ... on ContentfulModelMaster {
          id
          title
          slug
          multifamilyUnit
          designPartner {
            id
            name
            details {
              details
            }
          }
          model__variation_ {
            baths
            beds
            squareFootage
            images {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              file {
                url
              }
              title
            }
          }
          configuratorUrl
        }
      }
    }
  }
`
